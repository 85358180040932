import commonStyles from '../styles/components/common.module.css';
import inputStyles from '../styles/components/Input.module.css';
import { toast } from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import { SET_RESPONSE } from '../queries/question'
import { convertTimetoSeconds } from '../utils/helpers'
import React, { useState, useEffect } from 'react';


const QTnwBallot = ({user, question, currentSeconds, responses}) => {
  const secondsLeft = Math.round(convertTimetoSeconds(question.starttime) + question.durationseconds - currentSeconds,0)
  //const userRoles = ["public"].concat(Array.from(user.roles))
  //const userRoles = ["public", "responder"]
  const [setResponse, { loading: updatingResponse }] = useMutation(SET_RESPONSE)
  // const [setResponse, { loading: updatingResponse }] = useMutation(SET_RESPONSE, {
  //   context: {
  //     headers: {
  //       'x-hasura-role': 'responder'
  //     }
  //   }
  // })

  const [noneOfTheAbove, setNoneOfTheAbove] = useState(false);
  const [votes, setVotes] = useState([]);

  const [writeIn, setWriteIn] = useState('');
  const [writeInList, setWriteInList] = useState([]);

  const handleWriteInClick = async e => {
    e.preventDefault();
    if (writeIn.trim()) {
      setWriteInList([...writeInList, writeIn]);
      setWriteIn('');
    }
  };

  const handleCheck = async e => {
    if(e.target.checked) {
      setVotes([...votes,e.target.value]);
    } else {
      setVotes(votes.filter(item => item !== e.target.value));
    }
  };

  const handleWriteInChange = async e => {
    setWriteIn(e.target.value);
  };

  const handleWriteInCheck = async e => {
    if(e.target.checked) {
      setWriteInList([...writeInList,e.target.value]);
    } else {
      setWriteInList(writeInList.filter(item => item !== e.target.value));
    }
  };

  useEffect(() => {
    if (noneOfTheAbove) {
      setWriteInList([]);
      setVotes([]);
    }
  }, [noneOfTheAbove]);

  const [hasVoted, setHasVoted] = useState(false);
  const handleOnSubmitBallot = async e => {
    e.preventDefault();
    try {
      await setResponse({
        variables: {
          user: user.id,
          question: e.target.id,
          privatemeta: {
            "selected": votes
            ,"writeInList": writeInList
            ,"noneOfTheAbove": noneOfTheAbove
          }
        }
      })
      toast.success('Response recorded', { id: 'setResponse' })
      setHasVoted(true);
    } catch (error) {
      toast.error('Unable to record response\n\n' + error.message, { id: 'setResponse' })
    }
  };

  return (
    <div className={commonStyles.card} key={question.id}>
        <h3 className={commonStyles['sub-title']}>{question.label}</h3>
        {(secondsLeft < 0) ? <p className={commonStyles.text}>Expired</p> : <>
          <p className={commonStyles.text}>{secondsLeft} seconds left</p>
          {hasVoted ? <p>Your vote has been received.</p> : <>
            <p className={commonStyles.text}>Make up to {question.publicmeta?.maxSelections} choices</p>
            {/* <p className={commonStyles.text}>12 of {question.publicmeta?.maxSelections} choices left</p> */}
            <form id={question.id} name="SendVote" onSubmit={handleOnSubmitBallot} className={commonStyles.form}>
              {question.publicmeta?.choices.map((choice)=>(
                <div className={inputStyles.inlineContainer}>
                  <input className={inputStyles.checkbox} type="checkbox" id={choice.slug} name={choice.slug} value={choice.slug} disabled={!(votes.indexOf(choice.slug) > -1) && (votes.length >= question.publicmeta?.maxSelections)} checked={votes.indexOf(choice.slug) > -1} onClick={handleCheck} />
                  <label className={inputStyles.label} for={choice.slug}>{choice.title}</label>
                </div>
              ))}

              {writeInList.map((choice)=>(
                <div className={inputStyles.inlineContainer}>
                  <input className={inputStyles.checkbox} type="checkbox" id={choice} name={choice} value={choice} checked={true} onClick={handleWriteInCheck} />
                  <label className={inputStyles.label} for={choice}>{choice}</label>
                </div>
              ))}

              <div className={inputStyles.inlineContainer}>
                <input className={inputStyles.input} type="text" id="newWriteIn" name="chosen" onChange={handleWriteInChange} value={writeIn} />
                <button className={commonStyles.button} style={{width: '150px', color: 'black', backgroundColor: 'yellow'}} onClick={handleWriteInClick}>Add Write In</button>
              </div>

              <div className={inputStyles.inlineContainer}>
                <input className={inputStyles.checkbox} type="checkbox" checked={noneOfTheAbove} onClick={() => setNoneOfTheAbove(!noneOfTheAbove)} id="nota" name="nota" />
                <label className={inputStyles.label} for="nota">NOTA: None Of The Above</label>
              </div>
                <button
                  type="submit"
                  disabled={false}
                  className={commonStyles.button}
                  style={{backgroundColor: 'green'}}
                >Send Ballot</button>
            </form>
          </>}
        </>}
        <p className={commonStyles.text}>{responses.length} ballots submitted.</p>
        <ul className={commonStyles.responseList}>{
          responses.map((response,index) => (
            <li className={commonStyles.responseListItem} key={index}><span className={commonStyles["Abstain"]}>{response.userByUser.displayName}</span></li>
          ))
        }</ul>
    </div>
  )
};

export default QTnwBallot;