import React, { useState } from 'react'
import styled from 'styled-components'
import GridList from '../components/GridList'
import { useMutation } from "@apollo/client"
import { DELETE_SEEKERS } from '../utils/queries'
import { Confirm, Header, Label, Message, Segment } from 'semantic-ui-react'
import { IsUserAllowedAccess, GroupBy } from '../utils/helpers'

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`

const Queue = ({ user, list, label, significantEntry }) => {
  //const userRoles = ["public"].concat(Array.from(user.roles))
  const userRoles = ["public", "seeker", "recognizer"]
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [itemToRemove, setItemToRemove] = useState({});
  const [serverError, setServerError] = useState('')
  const [serverStatus, setServerStatus] = useState('')
  const [serverSuccess, setServerSuccess] = useState('')

  //seeker
  const [deleteSelfAsSeeker] = useMutation(DELETE_SEEKERS, {
    context: {
      headers: {
        'x-hasura-role': 'user'
      }
    }
  })

  const [delteSeekers] = useMutation(DELETE_SEEKERS, {
    context: {
      headers: {
        'x-hasura-role': 'recognizer'
      }
    }
  })

  async function removeFromQueue(seekersToRemove) {
    let removeResponse = null
    if ( IsUserAllowedAccess(userRoles, ["recognizer"]) ) {
      removeResponse = await delteSeekers({ variables: {_in: seekersToRemove.map(s => s.id)} })
    } else if ( seekersToRemove.every(s => s.label === user.displayName) ) {
      removeResponse = await deleteSelfAsSeeker({ variables: {_in: seekersToRemove.map(s => s.id)} })
    }
    const deletedSeekers = removeResponse.data.delete_seekers.returning || []
    setServerStatus('')
    setServerError('')
    setServerSuccess(`${deletedSeekers.length} entries removed from the queue.`)
    setShowConfirmModal(false)
  }
  const [hiddenTypes, setHiddenTypes] = useState([])
  const removeAll = (items) => {
    setServerStatus(`Removing ${items.length} entries from the queue.`)
    setServerSuccess('')
    setServerError('')
    if ( IsUserAllowedAccess(userRoles, ["recognizer"]) ) {
      removeFromQueue(items)
    } else {
      setServerStatus('')
      setServerSuccess('')
      setServerError('You do not have persmission to bulk delete entries.')
    }
  };
  const removeOne = (item) => {
    setItemToRemove(item)
    setServerStatus(`Removing ${item.label} - ${item.detail} from the queue.`)
    setServerSuccess('')
    setServerError('')
    if ( user.displayName === item.label || IsUserAllowedAccess(userRoles, ["recognizer"]) ) {
      setShowConfirmModal(true)
    } else {
      setServerStatus('')
      setServerSuccess('')
      setServerError('You do not have persmission to delete entries except your own.')
    }
  };
  const toggleVisibilityAll = (type) => {
    hiddenTypes.indexOf(type) > -1
      ? setHiddenTypes(hiddenTypes.filter((i) => i !== type))
      : setHiddenTypes([...hiddenTypes, type])
  };

    const typedPeople = GroupBy(list, 'type')
    const types = Object.keys(typedPeople)
    const typeSummary = types.sort().map((type) => (
      <Label
        as="a"
        style={{ marginBottom: '6px' }}
        color={typedPeople[type][0].color}
        basic={hiddenTypes.includes(type) ? true : null}
        key={type}
        onRemove={(e) => {
          e.preventDefault();
          e.stopPropagation();
          removeAll(typedPeople[type]);
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggleVisibilityAll(typedPeople[type][0].type);
        }}
        detail={typedPeople[type].length || 0}
        content={type}
      />
    ))
  
    list.sort((a, b) => Date.parse(a.created_at) - Date.parse(b.created_at) )
    const filteredPeople = list.filter((person) => !hiddenTypes.includes(person.type)).sort((a, b) => a.sort - b.sort)
    const myPositions = list.map((item, index) => ({position: index + 1, item: item})).filter((mappedItem) => mappedItem.item.label === significantEntry)
    const myLabels = myPositions.map((currentPosition) => (
      <Label
        as="span"
        style={{ marginBottom: '6px' }}
        color={currentPosition.item.color}
        basic
        key={currentPosition.position}
        onRemove={(e) => {
          e.preventDefault();
          e.stopPropagation();
          removeOne(currentPosition.item);
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          removeOne(currentPosition.item);
        }}
        content={`You are in position ${currentPosition.position}`}
      />
    ));
  
    return (
      <Segment.Inline compact="true" style={{ margin: '16px' }}>
        <Header>
          {label} ({list && list.length} Entries)
        </Header>
        <Wrapper>{typeSummary}</Wrapper>
        <GridList
          gridColumnCount={1}
          gridCellSize="auto"
          gridGap="2px"
          styleString="max-height: 250px; overflow-y: auto;"
          renderDetails
          primaryAxis="column"
          onClickCallback={removeOne}
          renderNumbers={false}
          list={Array.isArray(filteredPeople) ? filteredPeople : []}
        />
        <Header>{myLabels}</Header>
        { !serverStatus ? null : 
          <Message info onDismiss={() => setServerStatus("")}>{serverStatus}</Message>
        }
        { !serverError ? null : 
          <Message error onDismiss={() => setServerError("")}>{serverError}</Message>
        }
        { !serverSuccess ? null : 
          <Message success onDismiss={() => setServerSuccess("")}>{serverSuccess}</Message>
        }
        <Confirm
            open={showConfirmModal}
            content={ `Remove ${itemToRemove.label} - ${itemToRemove.detail} from the ${itemToRemove.group} queue?`}
            onCancel={() => {
              setServerStatus("")
              setShowConfirmModal(false)
            }}
            onConfirm={() => removeFromQueue([itemToRemove])}
          />
      </Segment.Inline>
    )
  }

  export default Queue;