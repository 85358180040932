import commonStyles from '../styles/components/common.module.css';
import styles from '../styles/pages/common.module.css';

import { UPDATE_USER_MUTATION } from '../queries/user'
import { toast } from 'react-hot-toast'
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useOutletContext } from 'react-router-dom';
import Input from '../components/Input';
import { useChangePassword } from '@nhost/react';
import { useMutation } from '@apollo/client'

const Profile = () => {
  const { user } = useOutletContext();
  const { changePassword, isLoading: updatingPassword } = useChangePassword();
  const [mutateUser, { loading: updatingProfile }] = useMutation(UPDATE_USER_MUTATION);

  const [firstName, setFirstName] = useState(user?.metadata?.firstName ?? '');
  const [lastName, setLastName] = useState(user?.metadata?.lastName ?? '');

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const isFirstNameDirty = firstName !== user?.metadata?.firstName;
  const isLastNameDirty = lastName !== user?.metadata?.lastName;
  const isProfileFormDirty = isFirstNameDirty || isLastNameDirty;

  const isPasswordFormValid = password !== '' && password === confirmPassword;

  const updateUserProfile = async e => {
    e.preventDefault();

    try {
      await mutateUser({
        variables: {
          id: user.id,
          displayName: `${firstName} ${lastName}`.trim(),
          metadata: {
            firstName,
            lastName
          }
        }
      })
      toast.success('Updated successfully', { id: 'updateProfile' })
    } catch (error) {
      toast.error('Unable to update profile', { id: 'updateProfile' })
    }
  };

  const updatePassword = async e => {
    e.preventDefault();

    const { isError, isSuccess } = await changePassword(password);

    if (isError) {
      toast.error('Unable to update password', { id: 'updatePassword' });
    } else if (isSuccess) {
      toast.success('Updated successfully', { id: 'updatePassword' });
      setPassword('');
      setConfirmPassword('');
    }
  };

  return (<>
    <Helmet>
      <title>Profile - WebMeetings</title>
    </Helmet>


    <div className={commonStyles.container}>
      <h2 className={commonStyles.title}>Profile</h2>
      <h3 className={commonStyles['sub-title']}>Update personal information</h3>
      <div className={commonStyles.card}>
          <form onSubmit={updateUserProfile} className={commonStyles.form}>
            <div className={commonStyles['input-group']}>
              <Input
                type="text"
                label="First name"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                required
              />
              <Input
                type="text"
                label="Last name"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                required
              />
            </div>
            <div className={styles['input-email-wrapper']}>
              <Input
                type="email"
                label="Email address"
                value={user?.email}
                readOnly
              />
            </div>
            <button
              type="submit"
              disabled={!isProfileFormDirty || updatingProfile}
              className={commonStyles.button}
            >
              {updatingProfile ? 'Updating...' : 'Update'}
            </button>
          </form>
        </div>
        <h3 className={commonStyles['sub-title']}>Change Password</h3>
        <div className={commonStyles.card}>
          <form onSubmit={updatePassword} className={commonStyles.form}>
            <Input
              type="password"
              label="New password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
            />
            <Input
              type="password"
              label="Confirm password"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              required
            />
            <button
              type="submit"
              disabled={!isPasswordFormValid || updatingPassword}
              className={commonStyles.button}
            >
              {updatingPassword ? 'Updating...' : 'Change password'}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Profile;
