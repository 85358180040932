import { Helmet } from 'react-helmet';
import SignIn from '../components/SignIn';

const SignInPage = () => {
  return (<>
    <Helmet>
      <title>Sign In - WebMeetings</title>
    </Helmet>
    <SignIn />
  </>);
};

export default SignInPage;