import { gql } from '@apollo/client';

export const GET_QUEUES_WITH_SEEKERS = gql`
subscription GetQueuesWithSeekers {
  queuesold {
    id
    display_order
    display_name
    button_icon
    button_color
    queue_reasons {
      id
      type
      detail
      icon
      color
      display_order
    }
    seekers {
      id
      color
      label
      group
      detail
      created_at
      type
      user {
        id
        displayName
      }
    }
  }
}
`;

export const DELETE_SEEKERS = gql`
mutation DeleteSeekers($_in: [uuid!] = "") {
  delete_seekers(where: {id: {_in: $_in}}) {
  returning {
    id
  }
  }
}
`;

export const INSERT_SEEKER = gql`
mutation insertSeeker($color: String = "", $detail: String = "", $label: String = "", $group: String = "", $attendees_id: uuid = "", $user_id: uuid = "", $type: String = "", $queues_id: uuid = "") {
  insert_seekers_one(object: {type: $type, queues_id: $queues_id, attendees_id: $attendees_id, user_id: $user_id, detail: $detail, group: $group, label: $label, color: $color}) {
    id
  }
}
`;