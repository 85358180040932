import { gql } from '@apollo/client'

export const GET_MEETING_QUESTIONS_SUBSCRIPTION = gql`
  subscription GetMeetingQuestions($meeting: uuid!) {
    questions(where: {meeting: {_eq: $meeting}}) {
      id
      durationseconds
      meeting
      publicmeta
      startdate
      starttime
      status
      label
      description
    }
  }
`

export const GET_MEETING_QUESTION_RESPONSES_SUBSCRIPTION = gql`
  subscription GetMeetingQuestionResponses($meeting: uuid!) {
    responses(where: {questionByQuestion: {meeting: {_eq: $meeting}}}, order_by: {question: asc, createdAt: asc}) {
        id
      createdAt
      privatemeta
      publicmeta
      question
      status
      user
      userByUser {
        displayName
      }
    }
  }
`

export const SET_RESPONSE = gql`
  mutation SetResponse($user: uuid = ""
  , $question: uuid = ""
  , $privatemeta: jsonb = "") {
    insert_responses_one(object: {user: $user
      , question: $question
      , privatemeta: $privatemeta})
    {
      id
    }
  }
`
