import { Helmet } from 'react-helmet';
import ResetPassword from '../components/ResetPassword';

const ResetPasswordPage = () => {
  return (<>
    <Helmet>
      <title>Reset Password - WebMeetings</title>
    </Helmet>
    <ResetPassword />
  </>);
};

export default ResetPasswordPage;