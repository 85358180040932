import { useOutletContext } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSubscription } from "@apollo/client";
import { GET_QUEUES_WITH_SEEKERS } from '../utils/queries'
import Queue from '../components/Queue'
import QueueButton from '../components/QueueButton'
import styled from 'styled-components'

const QueuesWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
`;
const QueueWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

const QueuesPage = () => {
  const { user } = useOutletContext();

  const { loading, error, data } = useSubscription(GET_QUEUES_WITH_SEEKERS);
   if (loading) return <div>Loading</div>;
   if (error) console.error(error);
  
  return (<>
    <Helmet>
      <title>Queues - WebMeetings.App</title>
      <meta name="description" content="WebMeetings.App" />
    </Helmet>
      {!data ? (
        "No queue data"
      ) : (
        <QueuesWrapper>
          {data.queuesold.sort((a,b)=>a.display_order-b.display_order).map((queue) => {
            return <QueueWrapper key={queue.id}>
              <QueueButton
                label={queue.display_name}
                user={user}
                queue_id={queue.id}
                color={queue.button_color}
                icon={queue.button_icon}
                list={queue.queue_reasons}
                key={`Button_${queue.id}`}
              />
              <Queue
                user={user}
                label={queue.display_name}
                list={queue.seekers}
                significantEntry={user.displayName}
                key={`Queue_${queue.id}`}
              />
            </QueueWrapper>
          })}
        </QueuesWrapper>
      )}
  </>)
};

export default QueuesPage;
