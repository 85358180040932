import commonStyles from '../styles/components/common.module.css';
import { useState } from 'react'
import { useSignUpEmailPassword } from '@nhost/react'
import { Link, Navigate } from 'react-router-dom'
import Input from '../components/Input'
import Spinner from '../components/Spinner'

const SignUp = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { signUpEmailPassword, isLoading, isSuccess, needsEmailVerification, isError, error } =
    useSignUpEmailPassword()

  const handleOnSubmit = (e) => {
    e.preventDefault()

    signUpEmailPassword(email, password, {
      displayName: `${firstName} ${lastName}`.trim(),
      metadata: {
        firstName,
        lastName
      }
    })
  }

  if (isSuccess) {
    return <Navigate to="/" replace={true} />
  }

  const disableForm = isLoading || needsEmailVerification

  return (<div className={commonStyles.container}>
    <h2 className={commonStyles['title']}>WebMeetings App</h2>
    <h3 className={commonStyles['sub-title']}>Sign Up</h3>
    <div className={commonStyles['card']}>
      {needsEmailVerification ? (
        <p className={commonStyles['verification-text']}>
          Please check your mailbox and follow the verification link to verify your email.
        </p>
      ) : (
        <form onSubmit={handleOnSubmit} className={commonStyles.form}>
          <div className={commonStyles['input-group']}>
            <Input
              label="First name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              disabled={disableForm}
              required
            />
          <Input
              label="Last name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              disabled={disableForm}
              required
            />
        </div>
        <Input
            type="email"
            label="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={disableForm}
            required
          />
          <Input
            type="password"
            label="Create password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={disableForm}
            required
          />

          <button type="submit" disabled={disableForm} className={commonStyles.button}>
            {isLoading ? <Spinner size="sm" /> : 'Create account'}
          </button>

          {isError ? <p className={commonStyles['error-text']}>{error?.message}</p> : null}
        </form>
      )}
    </div>

    <p className={commonStyles.text}>
      Already have an account?{' '}
      <Link to="/sign-in" className={commonStyles.link}>
        Sign in
      </Link>
    </p>
    </div>
  )
}

export default SignUp