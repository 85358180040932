import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { NhostClient, NhostProvider } from '@nhost/react'
import { NhostApolloProvider } from '@nhost/react-apollo'

import ProtectedRoute from './components/ProtectedRoute'
import Layout from './components/Layout';

import SignUpPage from './pages/SignUp';
import SignInPage from './pages/SignIn';
import ResetPasswordPage from './pages/ResetPassword';
import Profile from './pages/Profile';

import Dashboard from './pages/Dashboard';
import Delegations from './pages/Delegations';
import Questions from './pages/Questions';
import QueuesPage from './pages/Queues';
import Settings from './pages/Settings';

const nhost = new NhostClient({
  subdomain: process.env.REACT_APP_NHOST_SUBDOMAIN,
  region: process.env.REACT_APP_NHOST_REGION
})

function App() {
  return (
    <>
      <NhostProvider nhost={nhost}><NhostApolloProvider nhost={nhost}>
        <BrowserRouter>
          <Routes>
            <Route path="sign-up" element={<SignUpPage />} />
            <Route path="sign-in" element={<SignInPage />} />
            <Route path="reset-password" element={<ResetPasswordPage />} />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Layout />
                </ProtectedRoute>
              }
            >
              <Route index element={<Dashboard />} />
              <Route path="profile" element={<Profile />} />
              <Route path="queues" element={<QueuesPage />} />
              <Route path="questions" element={<Questions />} />
              <Route path="delegations" element={<Delegations />} />
              <Route path="settings" element={<Settings />} />
            </Route>
          </Routes>
        </BrowserRouter>
        </NhostApolloProvider></NhostProvider>
      <Toaster />
    </>
  );
}

export default App;
