import commonStyles from '../styles/components/common.module.css';

import { useState } from 'react'
import { useSignInEmailPassword } from '@nhost/react'
import { Link, Navigate } from 'react-router-dom'
import Input from '../components/Input'
import Spinner from '../components/Spinner'

const SignIn = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { signInEmailPassword, isLoading, isSuccess, needsEmailVerification, isError, error } =
    useSignInEmailPassword()

  const handleOnSubmit = (e) => {
    e.preventDefault()
    signInEmailPassword(email, password)
  }

  if (isSuccess) {
    return <Navigate to="/" replace={true} />
  }

  const disableForm = isLoading || needsEmailVerification

  return (<div className={commonStyles.container}>
      <h2 className={commonStyles['title']}>WebMeetings App</h2>
      <h3 className={commonStyles['sub-title']}>Sign In</h3>
      <div className={commonStyles['card']}>
        {needsEmailVerification ? (
          <p className={commonStyles['verification-text']}>
            Please check your mailbox and follow the verification link to verify your email.
          </p>
        ) : (
          <form onSubmit={handleOnSubmit} className={commonStyles.form}>
            <Input
              type="email"
              label="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={disableForm}
              required
            />
            <Input
              type="password"
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={disableForm}
              required
            />

            <button type="submit" disabled={disableForm} className={commonStyles.button}>
              {isLoading ? <Spinner size="sm" /> : 'Sign in'}
            </button>

            {isError ? <p className={commonStyles['error-text']}>{error?.message}</p> : null}
          </form>
        )}
      </div>

      <p className={commonStyles.text}>
        No account yet?{' '}
        <Link to="/sign-up" className={commonStyles.link}>
          Sign up
        </Link>
      </p>
      <p className={commonStyles.text}>
        Forgot your password?{' '}
        <Link to="/reset-password" className={commonStyles.link}>
          Request a reset
        </Link>
      </p>
    </div>
  )
}

export default SignIn