import styles from '../styles/pages/common.module.css';

import { useOutletContext } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Settings = () => {
  const { user } = useOutletContext();

  return (
    <>
      <Helmet>
        <title>Settings - WebMeetings</title>
      </Helmet>

      <div>
        <h2 className={styles.title}>Settings</h2>
      </div>
    </>
  );
};

export default Settings;
