import { Helmet } from 'react-helmet';
import SignUp from '../components/SignUp';

const SignUpPage = () => {
  return (<>
    <Helmet>
      <title>Sign Up - WebMeetings</title>
    </Helmet>
    <SignUp />
  </>);
};

export default SignUpPage;