import styles from '../styles/pages/common.module.css';

import { useOutletContext } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import QTynaPoll from '../components/QTynaPoll';
import QTnwBallot from '../components/QTnwBallot';
import { convertTimetoSeconds } from '../utils/helpers'

import { GET_MEETING_QUESTIONS_SUBSCRIPTION, GET_MEETING_QUESTION_RESPONSES_SUBSCRIPTION } from '../queries/question'

import { useSubscription } from '@apollo/client'
import React, { useState, useEffect } from 'react';

const Questions = () => {
  const meeting = "cf8ef826-c763-4e77-be85-bfb6bb6ca42f";

  const now = new Date();
  const then = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      0,0,0);
  const [currentSeconds, setCurrentSeconds] = useState((new Date().getTime() - then.getTime())/1000);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSeconds((new Date().getTime() - then.getTime())/1000);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const { user } = useOutletContext();

  const { loading: isLoadingMeetingQuestions
    , error: isErrorMeetingQuestions
    , data: meetingQuestionsData } = useSubscription(GET_MEETING_QUESTIONS_SUBSCRIPTION, {
    variables: { meeting },
    skip: !meeting
  })

  const meetingQuestions = isLoadingMeetingQuestions? [] 
    : [...meetingQuestionsData?.questions]
      .sort((a,b)=>(convertTimetoSeconds(b.starttime) - convertTimetoSeconds(a.starttime)));

  const { loading: isLoadingMeetingQuestionResponses
    , error: isErrorMeetingQuestionResponses
    , data: meetingQuestionResponsesData } = useSubscription(GET_MEETING_QUESTION_RESPONSES_SUBSCRIPTION, {
    variables: { meeting },
    skip: !meeting
  })
  const meetingQuestionResponses = isLoadingMeetingQuestionResponses? [] 
    : [...meetingQuestionResponsesData?.responses];


  return (
    <>
      <Helmet>
        <title>Questions - WebMeetings</title>
      </Helmet>

      <div>
        <h2 className={styles.title}>Questions</h2>
      </div>

      {[...meetingQuestions]        
        .map(question => (
          question.publicmeta?.type == "ynaPoll" ? (
            <QTynaPoll user={user} question={question} currentSeconds={currentSeconds} responses={meetingQuestionResponses.filter((response) => (response.question === question.id))} />
          ) : (
            <QTnwBallot user={user} question={question} currentSeconds={currentSeconds} responses={meetingQuestionResponses.filter((response) => (response.question === question.id))} />
          )
        ))
      }      
    </>
  );
};

export default Questions;
