import commonStyles from '../styles/components/common.module.css';
import { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useResetPassword, useAuthenticationStatus } from '@nhost/react';
import Input from './Input';
import Spinner from './Spinner';

const ResetPassword = () => {
  const [email, setEmail] = useState('');

  const { isLoading: isLoadingStatus, isAuthenticated } =
    useAuthenticationStatus();

  const {
    resetPassword,
    isLoading: isResettingPassword,
    isSent,
    isError,
    error,
  } = useResetPassword();

  const handleOnSubmit = e => {
    e.preventDefault();
    resetPassword(email, { redirectTo: '/profile' });
  };

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (<div className={commonStyles.container}>
    <h2 className={commonStyles.title}>WebMeetings App</h2>
    <h3 className={commonStyles['sub-title']}>Reset Password</h3>
    { isLoadingStatus ? (<Spinner />) : (<>
      <div className={commonStyles.card}>
        {isSent ? (
          <p className={commonStyles.text}>
            An email has been sent to <b>{email}</b>. Please follow the link in
            the email to reset your password.
          </p>
        ) : (
          <form onSubmit={handleOnSubmit} className={commonStyles.form}>
            <Input
              type="email"
              label="Email address"
              value={email}
              onChange={e => setEmail(e.target.value)}
              disabled={isResettingPassword}
              required
            />
            <button
              type="submit"
              disabled={isResettingPassword}
              className={commonStyles.button}
            >
              {isResettingPassword ? <Spinner size="sm" /> : 'Send reset link'}
            </button>

            {isError ? (
              <p>{error?.message}</p>
            ) : null}
          </form>
        )}
      </div>
      <p className={commonStyles.text}>
        No account yet?{' '}
        <Link to="/sign-up" className={commonStyles.link}>
          Sign up
        </Link>
      </p>
      <p className={commonStyles.text}>
        Already have an account?{' '}
        <Link to="/sign-in" className={commonStyles.link}>
          Sign in
        </Link>
      </p>
    </>)}
  </div>);
};

export default ResetPassword;