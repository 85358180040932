import commonStyles from '../styles/components/common.module.css';
import { toast } from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import { SET_RESPONSE } from '../queries/question'
import { IsUserAllowedAccess, convertTimetoSeconds } from '../utils/helpers'

const QTynaPoll = ({user, question, currentSeconds, responses}) => {
  const secondsLeft = Math.round(convertTimetoSeconds(question.starttime) + question.durationseconds - currentSeconds,0)
  //const userRoles = ["public"].concat(Array.from(user.roles))
  const userRoles = ["public", "responder"]
  const [setResponse, { loading: updatingResponse }] = useMutation(SET_RESPONSE)
  // const [setResponse, { loading: updatingResponse }] = useMutation(SET_RESPONSE, {
  //   context: {
  //     headers: {
  //       'x-hasura-role': 'responder'
  //     }
  //   }
  // })

  const handleOnSubmit = async e => {
    e.preventDefault();
    try {
      await setResponse({
        variables: {
          user: user.id,
          question: e.target.id,
          privatemeta: {
            "response": e.target.name
          }
        }
      })
      toast.success('Response recorded', { id: 'setResponse' })
    } catch (error) {
      toast.error('Unable to record response\n\n' + error.message, { id: 'setResponse' })
    }
  };

  const responsedYes = responses.filter((response) => (response.privatemeta.response === "Yes"))
  const responsedAbstain = responses.filter((response) => (response.privatemeta.response === "Abstain"))
  const responsedNo = responses.filter((response) => (response.privatemeta.response === "No"))

  const responsedYesCount = responsedYes.length
  const responsedAbstainCount = responsedAbstain.length
  const responsedNoCount = responsedNo.length
  const responsedNotAbstainCount = responsedYes.length + responsedNo.length
  const responsedCount = responses.length

  const responsedYesPercentage = Math.round(responsedYesCount / responsedNotAbstainCount * 100,0) || 0
  const responsedNoPercentage = Math.round(responsedNoCount / responsedNotAbstainCount * 100,0) || 0

  return (
    <div className={commonStyles.card} key={question.id}>
      <h3 className={commonStyles['sub-title']}>{question.label}</h3>
      {(secondsLeft < 0) ? <p className={commonStyles.text}>Expired</p> : <>
        <p className={commonStyles.text}>{secondsLeft} seconds left</p>
        { !IsUserAllowedAccess(userRoles, ["responder"]) && updatingResponse ? null : <>
          <form id={question.id} name="Yes" onSubmit={handleOnSubmit} className={commonStyles.form}>
            <button
              type="submit"
              disabled={false}
              className={commonStyles.button}
              style={{backgroundColor: 'green'}}
            >Yes</button>
          </form>
          <form id={question.id} name="Abstain" onSubmit={handleOnSubmit} className={commonStyles.form}>
            <button
              type="submit"
              disabled={false}
              className={commonStyles.button}
              style={{backgroundColor: 'grey'}}
            >Abstain</button>
          </form>
          <form id={question.id} name="No" onSubmit={handleOnSubmit} className={commonStyles.form}>
            <button
              type="submit"
              disabled={false}
              className={commonStyles.button}
              style={{backgroundColor: 'red'}}
            >No</button>
          </form>
        </>}
      </>}
      <ul className={commonStyles.resultList}>
        <li className={commonStyles.resultListItem} key={question.id + 'YES'}><span className={commonStyles["Yes"]}>Yes&nbsp;~&nbsp;{responsedYesCount}&nbsp;~&nbsp;({responsedYesPercentage}%)</span></li>
        <li className={commonStyles.resultListItem} key={question.id + 'NO'}><span className={commonStyles["No"]}>No&nbsp;~&nbsp;{responsedNoCount}&nbsp;~&nbsp;({responsedNoPercentage}%)</span></li>
        <li className={commonStyles.resultListItem} key={question.id + 'ABSTAIN'}><span className={commonStyles["Abstain"]}>Abstain&nbsp;~&nbsp;{responsedAbstainCount}</span></li>
      </ul>
      <p className={commonStyles.text}>{responsedCount} responses submitted.</p>
      <ul className={commonStyles.responseList}>{
        responses.map((response,index) => (
          <li className={commonStyles.responseListItem} key={index}><span className={commonStyles[response.privatemeta.response]}>{response.userByUser.displayName}&nbsp;~&nbsp;{response.privatemeta.response.charAt(0)}</span></li>
        ))
      }</ul>
    </div>
  )
};

export default QTynaPoll;